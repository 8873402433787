<template>
  <div class="home">

  </div>
</template>

<script>
import axios from 'axios'
import settings from '../settings'

export default {
  data() {
    return {
      xwt: '',
      order_id: ''
    }
  },
  methods: {
    async get_status() {
      var headers = { xwt: this.xwt }
      var data = { order_id: this.order_id }

      axios.post(settings.api().endpoint + settings.api().path + '/get_transaction_status', data, { headers }).then( (r) => {
        this.$cookies.set('pay_status', r.data.data)
      })
    }
  },
  mounted() {
    this.xwt = this.$cookies.get('xwt')
    this.order_id = this.$route.params.order_id
    
    setTimeout(() => { this.get_status() }, 500);
  },
}
</script>